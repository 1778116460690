<template>
    <div>
        <img :src="backgroundImage"/>

        <div v-for="(number, index) in ticket.numbers"
             :key="index"
        >

            <img v-if="isNumberOrderlyMatch(index)"
                 :src="orderlyNumberImage(number)"

                 :style="getNumberPosition(index)"
                 class="result__number"
            />

            <img v-else-if="isNumberDisorderlyMatch(index) || isNumberMatch(index)"
                 :src="disorderlyNumberImage(number)"

                 :style="getNumberPosition(index)"
                 class="result__number"
            />

            <img v-else
                 :src="numberImage(number)"

                 :style="getNumberPosition(index)"
                 class="result__number"
            />
        </div>

        <span class="bet">
               Bet: {{ fromCents(ticket.bet) }}
        </span>

        <span class="win">
               Win: {{ fromCents(ticket.totalWin) }}
        </span>
    </div>
</template>

<script>
import {fromCents} from "../../../../../helpers/balanceConverter";

export default {
    props: {
        ticket: {
            type: Object,
            required: true,
            default() {
                return {
                    bet: null,
                    place: null,
                    numbers: null,
                    totalWin: null,
                    orderCombination: {
                        matchCount: null,
                        matchNumbers: null,
                        win: null,
                    },
                    disorderCombination: {
                        matchCount: null,
                        matchNumbers: null,
                        win: null,
                    },
                    matchNumbers: null,
                }
            }
        },
        resultNumbers: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            bg: {
                width: 356
            },
            margin: {
                left: 45
            }
        }
    },
    computed: {
        backgroundImage() {
            return require(`@/assets/games/keno/pick-keno/ticket_bg.png`);
        }
    },
    methods: {
        fromCents,
        numberImage(number) {
            return require(`@/assets/games/keno/pick-keno/gray/${number}.png`);
        },
        disorderlyNumberImage(number) {
            return require(`@/assets/games/keno/pick-keno/orange/${number}.png`);
        },
        orderlyNumberImage(number) {
            return require(`@/assets/games/keno/pick-keno/red/${number}.png`);
        },
        isNumberDisorderlyMatch(index) {
            return this.ticket.disorderCombination.matchNumbers.includes(index);
        },
        isNumberOrderlyMatch(index) {
            return this.ticket.orderCombination.matchNumbers.includes(index);
        },
        isNumberMatch(index) {
            return this.ticket.matchNumbers.includes(index);
        },
        getNumberPosition(index) {
            let left = this.margin.left * (index + 1);

            return {
                left: `${left}px`,
            }
        }
    }
}
</script>

<style scoped>
.result__number {
    position: absolute;
    top: 70%;
    transform: translate(0%, -70%);
    background-color: transparent;

    max-width: 35px;
    max-height: 51px;
}

.bet {
    position: absolute;
    left: 20%;
    top: 10%;

    font-weight: bold;
    font-size: 20px;
    color: #6d8b91;
    cursor: default;
    white-space: nowrap;
}

.win {
    position: absolute;
    left: 55%;
    top: 10%;

    font-weight: bold;
    font-size: 20px;
    color: #6d8b91;
    cursor: default;
    white-space: nowrap;
}
</style>
